<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="tipoGenNom"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(tipoGenNom, 50),
                    rules.requiredTrim(tipoGenNom)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditTipoGenerador",
  directives: { mask },
  props: ["tipoGenId"],
  data: () => ({
    isFormValid: false,
    formEditTitle: "Editar tipo de generador",
    title: enums.titles.GENERADORES_DE_EXPEDIENTES,
    addIcon: enums.icons.CIRCLE_ADD_ITEM,
    rules: rules,
    tipoGenNom: null
  }),
  created() {
    if (this.tipoGenId != null) {
      this.setTipoGenerador(this.tipoGenId);
    } else {
      this.newTipoGenerador();
    }
  },
  methods: {
    ...mapActions({
      getTiposGeneradoresDeExpedientesById:
        "configuracionME/getTiposGeneradoresDeExpedientesById",
      saveTiposGeneradoresDeExpedientes:
        "configuracionME/saveTiposGeneradoresDeExpedientes",
      setAlert: "user/setAlert"
    }),

    async setTipoGenerador() {
      const response = await this.getTiposGeneradoresDeExpedientesById(
        this.tipoGenId
      );

      this.tipoGenNom = response.tipoGenNom;
    },
    async newTipoGenerador() {
      this.formEditTitle = "Nuevo tipo de generador";
    },
    async saveEdit() {
      const data = {
        tipoGenId: this.tipoGenId,
        tipoGenNom: this.tipoGenNom
      };
      const res = await this.saveTiposGeneradoresDeExpedientes(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
