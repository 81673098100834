<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="tiposGeneradores"
      class="elevation-1"
      dense
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="end" v-if="canCreate">
              <v-btn color="primary" @click="openModal()" class="to-right">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModal(item.tipoGenId)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar tipo de generador</span>
        </v-tooltip>
        <v-tooltip left v-if="canDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="deleteTiposGenerador(item.tipoGenId)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Eliminar tipo de generador</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="450px"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTipoGenerador
        :tipoGenId="tipoGenId"
        @closeAndReload="closeAndReload"
      ></EditTipoGenerador>
    </v-dialog>
    <DeleteDialog
      class="deleteDialogTipoGenerador"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditTipoGenerador from "@/components/modules/mesaEntrada/configuracion/EditTipoGenerador.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  components: { PageHeader, DeleteDialog, EditTipoGenerador, GoBackBtn },
  name: "TiposGeneradores",
 
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    showDeleteModal: false,
    idToDelete: null,
    routeToGo: "GeneradoresExpedientes",
    title: enums.titles.TIPOS_DE_GENERADORES_EXPEDIENTES,
    titleDelete: "¿Eliminar registro?",
    rules: rules,
    tiposGeneradores: [],
    tipoGenId: null,
    headers: [
      {
        text: "Nombre",
        value: "tipoGenNom",
        align: "start",
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false,
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.loadTiposGeneradores();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposGeneradores: "configuracionME/getTiposGeneradores",
      deleteTiposGeneradoresDeExpedientes:
        "configuracionME/deleteTiposGeneradoresDeExpedientes",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_TIPO_GENERADOR_EXPEDIENTES:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_TIPO_GENERADOR_EXPEDIENTES:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_TIPO_GENERADOR_EXPEDIENTES:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadTiposGeneradores() {
      const data = await this.getTiposGeneradores();
      this.tiposGeneradores = data;
    },
    deleteTiposGenerador(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTiposGeneradoresDeExpedientes({
        id: this.idToDelete,
      });

      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadTiposGeneradores();
      }
    },
    openModal(tipoGenId) {
      this.openModalEdit = true;
      this.tipoGenId = tipoGenId;
    },

    closeAndReload() {
      this.openModalEdit = false;
      this.loadTiposGeneradores();
    },
  },
};
</script>

<style scoped></style>
